export default {
  public: [],
  admin: [
    // 驗證碼列表
    {
      path: 'otp',
      name: 'otp',
      component: () => import('@/modules/base/views/otpList.vue'),
    },

    // 驗證碼表單
    {
      path: 'otp/create',
      name: 'otp-create',
      meta: {
        formMode: 'create',
      },
      component: () => import('@/modules/base/views/otpForm.vue'),
    },
    {
      path: 'otp/update/:target',
      name: 'otp-update',
      meta: {
        formMode: 'update',
      },
      component: () => import('@/modules/base/views/otpForm.vue'),
    },

    // log-viewer
    {
      path: 'log-viewer',
      name: 'admin.log-viewer',
      component: () => import('@/modules/base/views/debug/logViewer.vue'),
    },

    // mail-template
    {
      path: 'mail-template',
      name: 'admin.mail-template',
      component: () => import('@/modules/base/views/debug/mailTemplate.vue'),
    },

    // html-editor
    {
      path: 'html-editor',
      name: 'admin.html-editor',
      component: () => import('@/modules/base/views/debug/htmlEditor.vue'),
    },

    // sitemap
    {
      path: 'sitemap',
      name: 'admin.sitemap',
      component: () => import('@/modules/base/views/debug/sitemap.vue'),
    },

    // 報表
    {
      path: 'report',
      name: 'report',
      component: () => import('@/modules/base/views/report.vue'),
    },

    // 雲市集報表
    {
      path: 'report-cloud',
      name: 'report.cloud',
      component: () => import('@/modules/base/views/reportCloud.vue'),
    },

    // 圖片列表
    {
      path: 'photo',
      name: 'photo',
      component: () => import('@/modules/base/views/photoList.vue'),
    },

    // 檔案列表
    {
      path: 'file',
      name: 'file',
      component: () => import('@/modules/base/views/fileList.vue'),
    },

    {
      path: 'app-config/linerp',
      name: 'app-config-linerp',
      meta: {
        formMode: 'update',
      },
      component: () => import('@/modules/base/views/linerpAppConfigForm.vue'),
    },
  ],
}
